/** @format **/

import * as React from 'react';
import { SnowplowLink } from 'components/snowplow';

const Footer: React.FunctionComponent = () => {
  return (
    <div className="footer-container">
      <div className="center center-max">
        <div className="footer-content">
          <div className="footer-content__navigation">
            <ul className="footer-navigation clearfix">
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickDocumentationLink'}>
                  <a className="footer-navigation__link" href="https://raygun.com/docs">
                    Documentation
                  </a>
                </SnowplowLink>
              </li>
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickSupportLink'}>
                  <a className="footer-navigation__link" href="https://raygun.com/about/contact">
                    Help / Support
                  </a>
                </SnowplowLink>
              </li>
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickStatusLink'}>
                  <a className="footer-navigation__link" href="http://status.raygun.com">
                    Status
                  </a>
                </SnowplowLink>
              </li>
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickPrivacyLink'}>
                  <a className="footer-navigation__link" href="https://raygun.com/privacy">
                    Privacy
                  </a>
                </SnowplowLink>
              </li>
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickTermsAndConditionsLink'}>
                  <a className="footer-navigation__link" href="https://raygun.com/terms">
                    Terms &amp; Conditions
                  </a>
                </SnowplowLink>
              </li>
              <li>
                <SnowplowLink interactionId={'sp-app-chrome-footer-clickTwitterLink'}>
                  <a
                    className="footer-navigation__link"
                    href="https://twitter.com/raygunio"
                    title="Twitter"
                    data-size="large"
                    data-show-count="false"
                  >
                    Follow &#64;raygunio
                  </a>
                </SnowplowLink>
              </li>
            </ul>
          </div>

          <div className="footer-content__secondary">&copy; {new Date().getFullYear()} Raygun</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
