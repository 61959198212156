/** @format **/

import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { connect, Dispatch } from 'react-redux';

import { getPlanIdentifier } from 'app/selectors/configuration';
import { AppState } from 'interfaces/appState';
import { isOnAuditLogPage, isOnUserSettingsPage } from 'utils/links';

import { Icon } from 'components/icon';

import { AlertingButton, ApplicationSwitcherButton, DashboardSwitcherButton } from './components';

import Emblem from './components/emblem';
import User from './components/user';

import {
  isAppSwitcherOpen,
  isDashboardSwitcherOpen,
  isSidebarOpen,
} from '../../../selectors/chrome';

import {
  showApplicationSwitcher,
  showDashboardSwitcher,
  toggleMobileSidebar,
} from '../../../actions/chrome';

export * from './anonymousHeader';

type PassedProps = {
  marionetteToggleSidebar?: () => void;
};

type StateProps = {
  isAppSwitcherOpen: boolean;
  isDashboardSwitcherOpen: boolean;
  isSidebarOpen: boolean;
  planIdentifier: string;
};

type DispatchProps = {
  showApplicationSwitcher: (state: boolean) => void;
  showDashboardSwitcher: (state: boolean) => void;
  toggleSidebar: (show: boolean) => void;
};

type State = {
  scrolled: boolean;
};

type Props = PassedProps & StateProps & DispatchProps;
export class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scrolled: false,
    };

    this.onScroll = this.onScroll.bind(this);
    this.toggleAppSwitcher = this.toggleAppSwitcher.bind(this);
    this.toggleDashboardSwitcher = this.toggleDashboardSwitcher.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const scrollY = window.scrollY;

    if (scrollY > 0 && !this.state.scrolled) {
      this.setState({
        scrolled: true,
      });
    } else if (scrollY <= 0 && this.state.scrolled) {
      this.setState({
        scrolled: false,
      });
    }
  }

  toggleAppSwitcher() {
    const currentState = this.props.isAppSwitcherOpen;
    this.props.showApplicationSwitcher(!currentState);
    if (!currentState && this.props.isDashboardSwitcherOpen) {
      this.props.showDashboardSwitcher(false);
    }
  }

  toggleDashboardSwitcher() {
    const currentState = this.props.isDashboardSwitcherOpen;
    this.props.showDashboardSwitcher(!currentState);
    if (!currentState && this.props.isAppSwitcherOpen) {
      this.props.showApplicationSwitcher(false);
    }
  }

  render() {
    const showAlertingButton = !isOnUserSettingsPage() && !isOnAuditLogPage();
    const layoutHeaderClass = classnames('layout__header layout__header--blue', {
      'raygun-header--shadow': this.state.scrolled,
    });

    return (
      <>
        <header className={layoutHeaderClass}>
          <div className="raygun-header">
            <div className="raygun-header__application">
              <div className="header-controls">
                {this.renderMobileSidebar()}

                <div className="header-controls__buttons">
                  <div className="header-controls-button" onClick={this.toggleAppSwitcher}>
                    <ApplicationSwitcherButton />
                  </div>
                  <div className="header-controls-button" onClick={this.toggleDashboardSwitcher}>
                    <DashboardSwitcherButton />
                  </div>
                  {showAlertingButton && (
                    <div className="header-controls-button">
                      <AlertingButton planIdentifier={this.props.planIdentifier} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="raygun-header__emblem">
              <Emblem />
            </div>
            <div className="raygun-header__user">
              <div className="raygun-header__user-setup"/>
              <div className="raygun-header__user-profile">
                <User/>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  renderMobileSidebar() {
    const classNames = classnames('header-button', {
      'header-button__active': this.props.isSidebarOpen,
    });

    return (
      <div
        className="header-controls__mobile"
        title="Show sidebar"
        onClick={() => {
          this.props.toggleSidebar(!this.props.isSidebarOpen);
          if (!isNil(this.props.marionetteToggleSidebar)) {
            this.props.marionetteToggleSidebar();
          }
        }}
      >
        <div className={classNames}>
          <Icon type="menu" size={16} />
        </div>
      </div>
    );
  }
}
export const ConnectedHeader = connect<StateProps, DispatchProps>(
  (state: AppState) => ({
    isSidebarOpen: isSidebarOpen(state),
    isAppSwitcherOpen: isAppSwitcherOpen(state),
    isDashboardSwitcherOpen: isDashboardSwitcherOpen(state),
    planIdentifier: getPlanIdentifier(state),
  }),
  (dispatch: Dispatch<AppState>) => ({
    toggleSidebar: (state: boolean) => dispatch(toggleMobileSidebar(state)),
    showApplicationSwitcher: (state: boolean) => dispatch(showApplicationSwitcher(state)),
    showDashboardSwitcher: (state: boolean) => dispatch(showDashboardSwitcher(state)),
  }),
)(Header);